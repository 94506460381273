import React from "react";
import Paper from "@material-ui/core/Paper";
import { Box, Typography } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";
import { ORANGE, RED, GREEN, BLUE, PINK } from "../../lib/custom-color";
import Image from "next/image";
const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      overflow: "hidden",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      boxShadow: "0px 8px 16px 0px rgb(0 0 0 / 15%)",
    },
    iconContainer: {
      width: theme.spacing(18.75),
      height: theme.spacing(18.75),
      borderRadius: "50% 50% 0% 50%",
      marginLeft: "auto",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      transform: "translate(15px, 15px)",
    },
    orange: {
      backgroundColor: ORANGE,
    },
    green: {
      backgroundColor: GREEN,
    },
    red: {
      backgroundColor: RED,
    },
    pink: {
      backgroundColor: PINK,
    },
    blue: {
      backgroundColor: BLUE,
    },
  })
);

const KeyDifferentiatorCard: React.FC<KeyDifferentiatorCardProps> = (props) => {
  const classes = useStyle();
  const { color = "red", icon, textContent } = props;
  return (
    <Paper elevation={4} className={classes.root} variant="outlined">
      <Box paddingX={2} paddingY={2} pb={0}>
        <Typography variant="h5" component="p">
          {textContent}
        </Typography>
      </Box>

      <div
        className={clsx(classes.iconContainer, {
          [classes.orange]: color === "orange",
          [classes.green]: color === "green",
          [classes.red]: color === "red",
          [classes.pink]: color === "pink",
          [classes.blue]: color === "blue",
        })}
      >
        {Boolean(icon) && (
          <Image
            src={icon}
            width={70}
            height={70}
            alt={textContent}
            loading="lazy"
          />
        )}
      </div>
    </Paper>
  );
};
export default KeyDifferentiatorCard;
