import React, { useMemo } from "react";
import Head from "next/head";
import { useRouter } from "next/router";
import replace from "lodash/replace";

const AlternateLinks: React.FC<CountriesProps> = ({ countries }) => {
  const router = useRouter();
  const { country: routeCountry } = router.query;
  const { asPath, pathname } = router;

  const filteredCountries = useMemo(() => {
    if (routeCountry) {
      return countries.filter((item) => item.short_name !== routeCountry);
    } else {
      return countries;
    }
  }, [routeCountry, countries]);

  const generateLinks = () => {
    const data = [];

    switch (pathname) {
      case "/":
      case "/[country]":
        filteredCountries.map((country) => {
          data.push({
            hrefLang: `en-${country.short_name}`,
            href: `${process.env.NEXT_PUBLIC_APP_URL}/${country.short_name}/`,
            type: "alternate",
          });
        });
        data.splice(0, 0, {
          hrefLang: "x-default",
          href: process.env.NEXT_PUBLIC_APP_URL,
          type: "alternate",
        });
        break;

      default:
        filteredCountries.map((country) => {
          data.push({
            hrefLang: `en-${country.short_name}`,
            href: `${process.env.NEXT_PUBLIC_APP_URL}/${
              country.short_name
            }${replace(asPath, `/${routeCountry}`, "")}`,
            type: "alternate",
          });
        });
        data.splice(0, 0, {
          hrefLang: "x-default",
          href:
            process.env.NEXT_PUBLIC_APP_URL +
            asPath.replaceAll(`/${routeCountry}`, ""),
          type: "alternate",
        });
        break;
    }

    data.push({
      hrefLang: undefined,
      href: `${process.env.NEXT_PUBLIC_APP_URL + asPath}`,
      type: "canonical",
    });

    return data;
  };

  return (
    <Head>
      {generateLinks().map(({ href, hrefLang, type }, index) => (
        <link key={index} rel={type} href={href} hrefLang={hrefLang} />
      ))}
    </Head>
  );
};

export default AlternateLinks;
