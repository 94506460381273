import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  Box,
  Container,
  Grid,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import GlobalOrganizationCard from "../cards/GlobalOrganizationCard";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  SwiperOptions,
  Grid as SliderGrid,
  Navigation,
  Pagination,
} from "swiper";
// Import Swiper styles
import "swiper/css";
import "swiper/css/grid";

import PaginationAndNavigation from "./PaginationAndNavigation";

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.background.default,
      "& .slick-slide > div": {
        margin: theme.spacing(2, 4),
        [theme.breakpoints.down("xs")]: {
          margin: theme.spacing(2, 0),
        },
        [theme.breakpoints.down("sm")]: {
          margin: theme.spacing(2, 2),
        },
      },
      "& .slick-list": {
        margin: theme.spacing(0, -4),
        [theme.breakpoints.down("xs")]: {
          margin: theme.spacing(0),
        },
        [theme.breakpoints.down("sm")]: {
          margin: theme.spacing(0, -2),
        },
      },
    },
    dots: {
      listStyle: "none",
      display: "flex!important",
      margin: 0,

      padding: 0,
      position: "absolute",
      right: -190,
      bottom: 78,
      // marginTop: theme.spacing(4),

      "& li": {
        "& div": {
          cursor: "pointer",
          transition: "all .5s",
          width: 20,
          height: 7,
          borderRadius: 50,
          border: `1px solid ${theme.palette.primary.main}`,
          marginLeft: 5,
        },
      },
      "& li.slick-active": {
        "& div": {
          width: 35,
          backgroundColor: theme.palette.primary.main,
        },
      },
    },
    navigationBox: {
      display: "flex",
      flexDirection: "column",
      [theme.breakpoints.down("xs")]: {
        flexDirection: "column-reverse",
      },
    },
  })
);

const GlobalOrganizationSlider: React.FC<GlobalOrganizationSliderProps> = ({
  content,
}) => {
  const classes = useStyle();
  const xs = useMediaQuery((theme: Theme) => theme.breakpoints.down("xs"));
  const sm = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  const swiperSliderOptions: SwiperOptions = {
    modules: [SliderGrid, Navigation, Pagination],
    navigation: {
      nextEl: ".slide-button-next",
      prevEl: ".slide-button-prev",
    },
    pagination: {
      el: ".slide-pagination",
      clickable: true,
      type: "bullets",
    },
    breakpoints: {
      "600": {
        slidesPerView: 1,
        slidesPerGroup: 1,
        spaceBetween: 16,
        grid: {
          rows: 2,
          fill: "row",
        },
      },
      "960": {
        slidesPerView: 2,
        slidesPerGroup: 2,
        spaceBetween: 24,
        grid: {
          rows: 2,
          fill: "row",
        },
      },
      "1170": {
        slidesPerView: 2,
        slidesPerGroup: 2,
        spaceBetween: 32,
        grid: {
          rows: 2,
          fill: "row",
        },
      },
    },
  };

  return (
    <Box className={classes.root} paddingY={[2, 3, 4, 5, 6]}>
      <Container>
        <Grid container spacing={xs ? 1 : sm ? 2 : 5} alignItems="center">
          <Grid item xs={12} sm={7}>
            <Swiper {...swiperSliderOptions}>
              {content.logos?.map((logo, index) => (
                <SwiperSlide key={`customer-logo-${index - 1}`}>
                  <GlobalOrganizationCard
                    imageUrl={
                      process.env.NEXT_PUBLIC_ADMIN_URL +
                      "storage/images/" +
                      logo.image_url
                    }
                    alt={logo.image_alt}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </Grid>
          <Grid item xs={12} sm={5} className={classes.navigationBox}>
            <Box>
              <Typography variant="h1" component="h2" gutterBottom>
                {content.heading}
              </Typography>
              <Typography color="secondary" variant="h4" component="em">
                {content.sub_heading}
              </Typography>
            </Box>
            <Box mt={[2, 3, 4, 5]}>
              <PaginationAndNavigation
                nextClassName="slide-button-next"
                prevClassName="slide-button-prev"
                paginationClassName="slide-pagination"
                paginationHideOnMobile={true}
              />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default GlobalOrganizationSlider;
