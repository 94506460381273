import React from "react";
import { Box, Container } from "@material-ui/core";
import Image from "next/image";

const Banner: React.FC<BannerProps> = ({ content }) => {
  const { variant, imageAlt, imageUrl } = content;
  if (variant === "contained") {
    return (
      <Box paddingY={[4, 6, 8, 10]}>
        <Container>
          <Image
            src={
              imageUrl
                ? process.env.NEXT_PUBLIC_ADMIN_URL +
                  "storage/images/" +
                  imageUrl
                : "/images/creative-banner.jpg"
            }
            style={{ width: "100%", height: "auto" }}
            width={1170}
            height={450}
            alt={imageAlt ? imageAlt : "Banner image"}
          />
        </Container>
      </Box>
    );
  }
  return (
    <Box paddingY={[4, 6, 8, 10]} component="section">
      <Image
        src={
          imageUrl
            ? process.env.NEXT_PUBLIC_ADMIN_URL + "storage/images/" + imageUrl
            : "/images/creative-banner.jpg"
        }
        style={{ width: "100%", height: "auto" }}
        width={1170}
        height={450}
        alt={imageAlt ? imageAlt : "Banner image"}
      />
    </Box>
  );
};

export default Banner;
